import React from 'react'

const HeaderAboutUs = (props) => (
    <header id="header">
        <h1>About Us</h1>
        <p>All about us</p>
    </header>
)

export default HeaderAboutUs
